// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query UsersList(
    $page: Int
    $first: Int
    $sorters: [DataGridSorterInput]
    $filters: [DataGridFilterInput]
  ) {
    list: users(
      page: $page
      first: $first
      sorters: $sorters
      filters: $filters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default QUERY;
